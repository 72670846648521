import React from 'react';
import useScript from '../../hooks/useScript';
import { Root, Container, StampImg, Title, Body } from './Terms.style';

import StampSrc from '../../images/stamp-beige.svg';

const Terms = () => {
  useScript('https://app.termly.io/embed-policy.min.js');

  return (
    <Root>
      <Container>
        <StampImg src={StampSrc} alt="" />

        <Body>
          <div name="termly-embed" data-id="452a4a14-f965-4b6c-ac66-824875d4f3d6" data-type="iframe"></div>
          {/* <Title>TERMS & CONDITIONS</Title>
          <p>
            The website displayed at jamesownbyreserve.com (this "Site") is operated
            by Ole Smoky Distillery, LLC. ("Ole Smoky"). Your use of and access
            to this Site is conditional upon your acceptance of these Terms &
            Conditions including our Privacy Policy. We reserve the right, in
            our sole discretion, to modify or update these Terms & Conditions
            from time to time with immediate effect. It is your responsibility
            to review these Terms & Conditions periodically for updates.
          </p>
          <p>
            AFTER READING THIS PAGE, IF FOR ANY REASON YOU DO NOT AGREE WITH OR
            CANNOT ABIDE BY THESE TERMS & CONDITIONS OR OUR PRIVACY POLICY,
            PLEASE EXIT THIS SITE IMMEDIATELY. OTHERWISE BY ACCESSING AND USING
            THIS SITE, YOU ARE AGREEING TO THESE TERMS OF USE AND OUR PRIVACY
            POLICY.
          </p>
          <p>
            <strong>PERMITTED USERS OF THIS SITE</strong>
            <br />
            This Site is intended to be used and accessed by people who are of
            legal age to purchase and consume alcohol. If you are of legal age
            in your country of residence and the country from which you are
            accessing this Site, please continue. If you are not, please exit
            this Site immediately.
          </p>
          <p>
            <strong>RIGHTS TO CONTENTS AND INTELLECTUAL PROPERTY</strong>
            <br />
            The copyrights to all materials, content and layout of this Site
            (including text, user and visual interfaces, images, look and feel,
            design, sound, etc., and any underlying software and computer codes)
            are proprietary to Ole Smoky, its parents, agencies, distributors,
            affiliates, subsidiaries, or third party licensors. You may not
            copy, reproduce, post on any other website, republish, upload,
            encode, modify, translate, publicly perform or display, commercially
            exploit, distribute or transmit any portion of this Site or make any
            derivative works from this Site in any way without Ole Smoky's
            express prior written consent.
          </p>
          <p>
            Any name, logo, trademark, service mark, patent, design, copyright
            or other intellectual property appearing on this Site is owned or
            licensed by Ole Smoky or its parents, affiliates or subsidiaries and
            may not be used by you without the prior written consent of Ole
            Smoky or the appropriate owner. Your use of this Site does not grant
            you any right, title, interest or license to any such intellectual
            property appearing on the Site.
          </p>
          <p>
            Any unauthorized use of the content of this Site may subject you to
            civil or criminal penalties.
          </p>
          <p>
            <strong>USE OF THIS SITE</strong>
            <br />
            Ole Smoky maintains this Site for your personal entertainment,
            information and education. You should feel free to browse the Site
            and may download material displayed on the Site for non-commercial,
            lawful, personal use only provided all copyright and other
            proprietary notices contained on the materials are retained and such
            information is not modified, copied or posted on any networked
            computer or broadcast in any media. All other copying (whether in
            electronic, hard copy or other format) is prohibited and may breach
            intellectual property laws and other laws world-wide. All commercial
            use of all or part of this Site is prohibited except with Ole
            Smoky's express prior written consent. All rights not expressly
            granted here are reserved to Ole Smoky. Please note that when
            visiting the site, certain non-personally-identifiable and aggregate
            information is collected. This data helps us to analyze and improve
            the usefulness of the information we provide on the website. This
            data may be utilized by third-party companies for use in providing
            advertisements about goods and services that may be of interest to
            you based on your web surfing activities including your activities
            on this website. Third-party companies may use non-cookie
            technologies to recognize your computer or device. Please note that
            neither your web browser nor its settings to block cookies may have
            any effect in allowing you to block their use. Please review our
            Privacy Policy (link below) to learn more about web based
            advertising and how to opt-out of this type of advertising.
          </p>
          <p>
            You may not use any computer program tools including, but not
            limited to, web spiders, bots, indexers, robots, crawlers,
            harvesters, or any other automatic device, program, algorithm or
            methodology, or any similar or equivalent manual process ("Tools")
            to access, acquire, copy or monitor any portion of the Site or any
            content, or in any way reproduce or circumvent the navigational
            structure or presentation of the Site or any content, to obtain or
            attempt to obtain materials, documents or information through any
            means not purposely made available through the Site. Tools that use
            the Site shall be considered agents of the individual(s) who control
            or author them.
          </p>
          <p>
            <strong>NO WARRANTIES</strong>
            <br />
            OLE SMOKY DOES NOT PROMISE THAT THIS SITE OR ANY CONTENT, SERVICE OR
            FEATURE OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY
            DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE
            SPECIFIC RESULTS. THE SITE AND ITS CONTENT ARE PROVIDED "AS IS" AND
            "AS AVAILABLE" BASIS WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY
            KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSES, NON-INFRINGEMENT OR ACCURACY.
          </p>
          <p>
            Ole Smoky also assumes no responsibility, and shall not be liable
            for any such damages caused by viruses or other forms of
            contamination or destructive features that may effect your computer
            equipment, software, data or other property on account of your
            access to, use of, or browsing in the Site or your downloading of
            any materials, text, images, video or audio from the Site or any
            linked sites.
          </p>
          <p>
            <strong>LIMITATION OF LIABILITY</strong>
            <br />
            In no event shall Ole Smoky, its parents, agencies, distributors,
            affiliates, subsidiaries and services providers, or the officers,
            directors, employees, shareholders, or agents of each of them, be
            liable for any damages of any kind, including without limitation any
            direct, special, incidental, indirect, exemplary, punitive or
            consequential damages, including lost profits, whether or not
            advised of the possibility of such damages, and on theory of
            liability what so ever, arising out of or in connection with the use
            or performance of, or your browsing in, or your links to other sites
            from, this Site. You acknowledge by your use of the Site, that your
            use of the Site is at your sole risk. Certain laws do not allow
            limitations on implied warranties or the exclusion or limitation of
            certain damages; if these laws apply to you, some or all of the
            above disclaimers may not apply, and you might have additional
            rights.
          </p>

          <p>
            <strong>INDEMNIFICATION</strong>
            <br />
            You agree to defend, indemnify and hold Ole Smoky harmless from and
            against any and all claims, damages, costs and expenses, including
            reasonable attorneys' fees, arising from or related to your use of
            the Site.
          </p>
          <p>
            <strong>ON-LINE STORES; PROMOTIONS</strong>
            <br />
            Additional terms and conditions may apply to purchases of goods or
            services and to specific portions or features of the Site, including
            but not limited to contests, sweepstakes, invitations, or other
            similar features (each an "Application"), all of which additional
            terms and conditions are made a part of these Terms of Use by this
            reference. You agree to abide by such Application terms and
            conditions. If there is a conflict between these Terms of Use and
            the terms of the Application, the terms of the Application will
            control as relating to the Application.
          </p>
          <p>
            <strong>COMMUNICATION WITH THIS SITE</strong>
            <br />
            By connecting to the Site through a third-party service (e.g.,
            Facebook), you give us permission to access and use information,
            content and/or material you have supplied to that service as
            permitted by that service, and to store your log-in credentials for
            that service.
          </p>
          <p>
            Please remember that you are solely responsible for your
            interactions with other users of the Site. We reserve the right, but
            have no obligation, to monitor disputes between you and any other
            user of the Site, or any user's action or inaction.
          </p>
          <p>
            You agree that you will abide by any third-party company policies
            and terms necessary in using our Website or Site (such as a
            third-party publisher terms of use or Facebook terms of use).
          </p> */}
        </Body>
      </Container>
    </Root>
  );
};

export default Terms;
